'use client'
import { Box, Container, HStack, Heading, SimpleGrid, VStack } from "@chakra-ui/react"
import BlogItem from "./BlogItems"
import { containerBreakpoints } from "@/components/breakpoints"
import { useEffect, useState } from "react"

const BlogPosts = (props: any) => {
    const { data, news = [], config = null } = props

    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, []) 

    const beforeDot = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        borderRadius: '50%',
        display: 'block',
        backgroundColor: '#cc0007',
        marginBottom: '16px',
        marginLeft: 'auto',
        marginRight: 'auto',

    }

    const getTagsName = () => {
        if (!data) return ''
        data?.Tags?.data.map((tag: any) => tag?.attributes?.Nome).join(', ')
    }

    const getClassName = () => !data ? '' : data?.ClassName


    

    return (
        <Box as="section" position={'relative'} padding={'100px 0 114px'} borderTop={'1px solid RGBA(1,1,1, 0.1)'} backgroundImage={'url(https://dr7dcgej7t392.cloudfront.net/uploads/testimonial_bg_1_light_7ba71d722b.webp)'} className="blog-posts">
            <Container maxW={containerBreakpoints}>
                <VStack position={'relative'} marginBottom={'50px'}>
                    <Heading as='h5' _before={beforeDot} fontFamily={"'Plus Jakarta Sans', sans-serif"} fontSize={14} fontWeight={'500'} textTransform={'uppercase'} lineHeight={1.2} letterSpacing={'1.4px'} margin='0 0 7px' position={'relative'}>
                        <span dangerouslySetInnerHTML={{__html: config?.BlogPosts.Subtitulo || 'Novidades Brasal'}} />
                    </Heading>
                    <Heading
                        fontSize={{ base: 30, sm: 30, md: '50px' }}
                        display={'block'}
                        textAlign={'center'}
                        letterSpacing={{ base: '-1px', sm: '-1px', md: '-2px' }}
                        margin={'0'}
                        fontWeight={800}
                        fontFamily={"'Plus Jakarta Sans', sans-serif"}
                        lineHeight={1.2} >
                            <span dangerouslySetInnerHTML={{__html: config?.BlogPosts.Titulo || 'Não perca nossos<br/>últimos acontecimentos'}} />
                    </Heading>
                </VStack>
                <HStack flexWrap={'wrap'} gap={0} alignItems={'start'} justifyContent={'center'} className={getClassName()} suppressHydrationWarning>
                    {news.map((item: any, index: number) => {
                        return <BlogItem key={`blog-item-${index}`} item={item} textoLink={config?.BotaoLeiaMais || 'Leia Mais'} />
                    })}
                    {!news.length && <>{config?.SemAtualizacoesTag || 'Sem atualizações recentes para tag(s)'} {getTagsName()}</>}
                </HStack>
            </Container>
        </Box>
    )
}

export default BlogPosts
