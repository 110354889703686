'use client'

import { useEffect, useState } from "react"
import CustonButton from "@/components/button"
import { Box, Heading, Image, VStack, Link } from "@chakra-ui/react"
import NextLink from 'next/link'
import NextImage from 'next/image'
import { useRouter } from "next/navigation"
import dayjs from "dayjs"



const BlogItem = (
    { item, textoLink, width = {lg: '33.3333333333%', md: '50%', base: '100%'} }: { item: any, textoLink:string,width?: any }
    
) => {
    const router = useRouter()
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    const onClick = (item:any) => {
        if (item?.attributes?.Tipo === 'pagina') {
            const route = `/${item?.attributes?.Slug}`
            /*router.push(route,{
                item: item
            })*/
            router.push(route)
            return
        } else if (item?.attributes?.Tipo === 'novidade') {
            const route = `/noticias/${item?.attributes?.Slug}`
            /*router.push(route,{
                item: item
            })*/
            router.push(route)
            return
        }
    }
    const getRoute = () => {
        if(item?.attributes?.Tipo === 'pagina') {
            return ''
        }
        let categoria = 'sem-categoria'
        if(item?.attributes?.hasOwnProperty('BlogCategorias') && item?.attributes?.BlogCategorias?.data.length) {
            categoria = item?.attributes?.BlogCategorias?.data[0]?.attributes?.Slug
        }
        
        return `/blog/${categoria}/${item?.attributes?.Slug}`
        //`${(item?.attributes?.Tipo === 'pagina' ? '' : '/noticias')}/${item?.attributes?.Slug}`
    }

    const linkBefore = {
        content: '""',
        width: '32px',
        height: '2px',
        backgroundColor: '#fff',
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }

    const linkAfter = {
        content: '""',
        width: '32px',
        height: '2px',
        backgroundColor: '#fff',
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(90deg)'

    }

    const listaCategorias = () => item?.attributes?.BlogCategorias?.data.map((cat:any) => cat?.attributes?.Nome).join(', ')
    
    //const date = dayjs(item?.attributes?.createdAt).format('DD/MM/YYYY')
    const date = item?.attributes?.Tipo !== 'pagina' 
        ? item?.attributes?.dataPublicacaoPost.split("-").reverse().join("/") 
        : ''

    return <>
        <Box width={width} flex={'0 0 auto'} paddingRight={'calc(1.5rem /2)'} paddingLeft={'calc(1.5rem /2)'} role="group" marginBottom={'60px'} >
            <Box position={'relative'}>
                {/*
                <Image 
                    htmlWidth="403" 
                    htmlHeight="368" 
                    objectFit={'cover'}
                    src={item?.attributes?.ImagemDestacada?.data?.attributes?.url} 
                    w={'100%'} 
                    h={368}
                    alt={item?.attributes?.ImagemDestacada?.data?.attributes?.alternativeText || 'imagem destacada'} />
                */}
                <NextImage 
                    width={403}
                    height={368}
                    src={item?.attributes?.ImagemDestacada?.data?.attributes?.url} 
                    unoptimized
                    loader={({src, width, quality}) => {
                        return item?.attributes?.ImagemDestacada?.data?.attributes?.url
                    }}
                    sizes="100vw"
                    // Make the image display full width
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '368px',
                    }}
                    alt={item?.attributes?.ImagemDestacada?.data?.attributes?.alternativeText || 'imagem destacada'} />
                <Link 
                    prefetch
                    href={getRoute()}
                    as={NextLink} 
                    _before={linkBefore} 
                    _after={linkAfter}
                    display={'flex'}
                    width={'100%'}
                    height={'100%'}
                    backgroundColor={'rgba(255,255,255,0.4)'}
                    position={'absolute'}
                    top={0}
                    left={0}
                    justifyContent={'center'}
                    alignItems={'center'}
                    opacity={0}
                    transform={'translateY(-20%)'}
                    transition={'opacity 500ms ease, transform 500ms ease'}
                    _groupHover={{ opacity: '1', transform: 'translateY(0)'}}
                />
                {item?.attributes?.Tipo !== 'pagina' &&
                <Box textTransform={'uppercase'} display={'inline-block'} lineHeight={'34px'} height={'34px'} letterSpacing={'1.2px'} fontWeight={'600'} fontSize={'12px'} pos={'absolute'} bottom={'20px'} right={'20px'} padding={'0 14.5px'} borderRadius={'100px'} backgroundColor={'white'}>
                    {date}
                </Box>}
            </Box>
            <VStack position={'relative'} padding={'24px 0 0'} fontFamily={"'Plus Jakarta Sans', sans-serif"} lineHeight={'1.875'} fontWeight={'500'}>
                <Box display={'flex'} w={'100%'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'start'} position={'relative'} marginBottom={'8px'} fontSize={'14px'} _before={{position: 'relative', content: '""', display: 'inline-block', backgroundColor:'#cc0007', w:'41px', h:'1px', marginRight:'10px'}}>
                    <Box display={'flex'} gap={2}>
                    {item?.attributes?.BlogCategorias?.data?.map((cat:any) => (
                        <Link key={cat.id} href={`?category=${cat?.attributes?.Slug}`}>{cat?.attributes?.Nome}</Link> 
                    ))}
                    </Box>
                </Box>
            </VStack>
            <Heading as="h3" 
                fontSize={'24px'} 
                letterSpacing={'-.96px'} 
                marginBlockStart={'1em'}
                marginBlockEnd={'1em'}
                marginBottom={'11px'} 
                paddingBottom={'30px'} 
                borderBottom={'1px solid rgba(0,0,0,0.1)'} 
                fontFamily={"'Plus Jakarta Sans', sans-serif"} 
                fontWeight={800}
                noOfLines={2}
                textDecoration={'none'}>
                <Link 
                    href={getRoute()}
                    as={NextLink}
                    _hover={{textDecoration: 'none'}}
                    _groupHover={{backgroundSize: '0% 1px, 100% 1px', color: '#cc0007'}}
                    display={'inline'}
                    backgroundSize={'0% 1px, 0 1px'}
                    backgroundPosition={'100% 100%, 0 100%'}
                    backgroundRepeat={'no-repeat'}
                    transition={'all 0.4s ease'}
                    backgroundImage={'linear-gradient(currentcolor, currentcolor), linear-gradient(currentcolor, currentcolor)'}>
                    {item?.attributes?.Titulo}
                </Link>
            </Heading>
            <Box>
                <CustonButton href={getRoute()} padding="3px 50px 4px">{textoLink}</CustonButton>
            </Box>
        </Box>
    </>
}

export default BlogItem
